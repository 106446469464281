import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosConfig from '../utils/axiosConfig';
import { useCookies } from 'react-cookie';


function Register() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [cookies] = useCookies(['is_logged_in']);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosConfig.post(process.env.REACT_APP_API_REGISTER, {
        email: email,
        first_name: firstName,
        last_name: lastName,
        password: password
      });
      navigate('/login');
    } catch (error) {
        if (error.response && error.response.data) {
            // Set the error message from the server response
            setError(error.response.data || 'Registration failed');
          } else {
            setError('Registration failed');
          }
    }
  };

  useEffect(() => {
    if (cookies.is_logged_in) {
      navigate('/');
    }
  }, [cookies, navigate]);

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <h1>Register</h1>
      <label>Email</label>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />

      <label>First Name</label>
      <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />

      <label>Last Name</label>
      <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />

      <label>Password</label>
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
      {error && <p className="error-message">{error}</p>}
      <button type="submit">Register</button>
    </form>
  );
}

export default Register;
