import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosConfig from '../utils/axiosConfig';

const EmailVerification = () => {
    const [verificationStatus, setVerificationStatus] = useState('');
    const { uid, token } = useParams();
    const navigate = useNavigate();

    const verifyEmail = useCallback(async () => {
        try {
            const response = await axiosConfig.get(`${process.env.REACT_APP_API_CHECK_ADMIN}${uid}/${token}/`);
            setVerificationStatus(response.data.message || 'Email verified successfully!');
            navigate('/login');
        } catch (error) {
            setVerificationStatus('Verification failed. Invalid or expired link.');
        }
    }, [uid, token, navigate]);

    useEffect(() => {
        if (uid && token) {
            verifyEmail();
        }
    }, [uid, token, verifyEmail]);

    return (
        <div>
            <h2>Email Verification</h2>
            {verificationStatus ? <p>{verificationStatus}</p> : <p>Verifying...</p>}
        </div>
    );
};

export default EmailVerification;
