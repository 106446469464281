import { useState, useMemo, createContext } from "react";
import { createTheme } from "@mui/material/styles";

export const tokens = (mode) => ({
    ...(mode === 'dark'?{
        primary: '#fd2049',
        secondary: '#ffffff',
        background: '#041C32',
        accent: '#ffffff',
        hover: '#333333'

    }:{
      primary: '#fd2049',
      secondary: '#000000',
      background: '#f5f5f5',
      accent: '#ffffff',
      hover: '#333333'
    })
})

// mui theme settings
export const themeSettings = (mode) => {
    const colors = tokens(mode);
    return {
      palette: {
        mode: mode,
        ...(mode === "dark"
          ? {
              // palette values for dark mode
              primary: {
                main: colors.secondary,
              },
              secondary: {
                main: colors.secondary,
              },
              neutral: {
                dark: colors.secondary,
                main: colors.secondary,
                light: colors.secondary,
              },
              background: {
                default: colors.background,
              },
            }
          : {
              // palette values for light mode
              primary: {
                main: colors.secondary,
              },
              secondary: {
                main: colors.secondary,
              },
              neutral: {
                dark: colors.secondary,
                main: colors.secondary,
                light: colors.secondary,
              },
              background: {
                default: colors.background,
              },
            }),
      },
      typography: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 12,
        h1: {
          fontFamily: ["Roboto", "sans-serif"].join(","),
          fontSize: 40,
        },
        h2: {
          fontFamily: ["Roboto", "sans-serif"].join(","),
          fontSize: 32,
        },
        h3: {
          fontFamily: ["Roboto", "sans-serif"].join(","),
          fontSize: 24,
        },
        h4: {
          fontFamily: ["Roboto", "sans-serif"].join(","),
          fontSize: 20,
        },
        h5: {
          fontFamily: ["Roboto", "sans-serif"].join(","),
          fontSize: 16,
        },
        h6: {
          fontFamily: ["Roboto", "sans-serif"].join(","),
          fontSize: 14,
        }
      },
    };
  };
  
  // context for color mode
  export const ColorModeContext = createContext({
    toggleColorMode: () => {},
  });
  
  export const useMode = () => {
    const [mode, setMode] = useState("light");
  
    const colorMode = useMemo(
      () => ({
        toggleColorMode: () =>
          setMode((prev) => (prev === "light" ? "dark" : "light")),
      }),
      []
    );
  
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    return [theme, colorMode];
  };