import axios from 'axios';

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "X-Api-Access": "secret"
  },
  withCredentials: true
});

export default axiosConfig;
