import React from "react";
import { ColorModeContext, useMode } from "../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import NavigationBar from "../Common/NavigationBar";
import HeroSection from "./HeroSection";
import AboutUsSection from "./AboutUs";
import ServicesSection from "./Services";
import ContactUsSection from "./ContactUs";
import Footer from "./Footer";
import Container from "../Common/Container";
import { tokens } from "../../theme";


const Home = () => {
  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <NavigationBar/>
          <Container id="home" backgroundImage={"images/background.jpg"}>
            <HeroSection/>
          </Container>
          <Container id="aboutus" bgColor={colors.background}>
            <AboutUsSection/>
          </Container>
          <Container id="services" bgColor={"#fff"}>
            <ServicesSection/>
          </Container> 
          <Container id="contact">
            <ContactUsSection/>
          </Container>
          <Container>
            <Footer/>
          </Container>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Home;
