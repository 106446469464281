import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const userInfo = useSelector((state) => state.user.userInfo);

  if (!userInfo) {
    // Redirect to login page if not logged in
    return <Navigate to="/login" />;
  } else if (allowedRoles && !allowedRoles.includes(userInfo.role)) {
    // Redirect to an unauthorized page or home if the role doesn't match
    return <Navigate to="/" />;
  }

  return children;
};
export default ProtectedRoute;
