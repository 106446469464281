import React from 'react';
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const userInfo = useSelector(state => state.user.userInfo);

  return (
    <div>
      <h1>Dashboard Page</h1>
      {userInfo && <p>Hello {userInfo.email}</p>}
    </div>
  );
};

export default Dashboard;
