export const logout = () => {
    return {
      type: 'LOGOUT',
    };
  };
  
// Action Types
export const SET_USER_INFO = 'SET_USER_INFO';

// Action Creators
export const setUser = (userInfo) => {
  return {
    type: SET_USER_INFO,
    payload: userInfo,
  };
};