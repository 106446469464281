import React, { useRef, useEffect, useState } from "react";
import { Grid, Typography, Slide, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const AboutUsSection = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showLogo, setShowLogo] = useState(false);
  const [showText, setShowText] = useState(false);

  const sectionRef = useRef(null);

  const handleIntersection = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setShowLogo(true);
      setShowText(true);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust the threshold as needed
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    // Capture sectionRef.current in a variable
    const currentSectionRef = sectionRef.current;

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, []);

  return (
<div style={{marginLeft: "16px"}}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={2} className="logo-container">
          <Slide
            direction="right"
            in={showLogo}
            timeout={1000}
            mountOnEnter
            unmountOnExit
          >
            <img
              src="svg_only_logo_viousvision_black.svg"
              alt="Vious Vision Logo"
              className="logo-svg"
            />
          </Slide>
        </Grid>
        <Grid item xs={12} md={10} ref={sectionRef}>
          <Slide
            direction="left"
            in={showText}
            timeout={1000}
            mountOnEnter
            unmountOnExit
          >
            <div className="about-content">
              <Typography variant="h2">About Us</Typography>
              <Typography variant="body1">
                Welcome to{" "}
                <span
                  style={{
                    color: colors.primary,
                    textTransform: "uppercase",
                  }}
                >
                  {" "}
                  Vious Vision
                </span>
                , where innovation, collaboration, and excellence converge to
                shape the future of software solutions. With a passion for
                technology and a dedication to delivering outstanding results,
                we're more than just a software development company – we're your
                partners in success.
              </Typography>
            </div>
          </Slide>
        </Grid>
      </Grid>
      <div className="mission-section">
        <Typography variant="h2">
          Our Mission: Fueling Innovation in Marketing and Software Excellence
        </Typography>
        <Typography variant="body1">
          <span style={{ color: colors.primary }}>
              At Vious Vision, we embark on a resolute mission: to ignite and
              shape digital landscapes, forging experiences that resonate and
              endure.
          </span>{" "}
          We thrive on the fusion of marketing prowess and software wizardry,
          harnessing technology to transmute visions into palpable realities.
          Whether it's choreographing an intuitive web platform, or weaving
          intricate scripts and automation, our mission is to metamorphose
          aspirations into tangible, user-centric marvels.
          <br />
          <br />
          <span style={{ color: colors.primary }}>
            Elevate, Automate, Innovate
          </span>
          : With a dynamic synergy of marketing ingenuity and software finesse,
          we empower your brand to ascend new heights. Our canvas is marked by
          automated symphonies that streamline operations, elegant scripts that
          amplify efficiency, and innovations that illuminate your path.{" "}
          <span style={{ color: colors.primary }}>
              At Vious Vision, we sculpt the future of marketing and software,
              layer by layer, pixel by pixel, code by code.
          </span>
        </Typography>
      </div>
    </div>
  );
};

export default AboutUsSection;
