import React, { useEffect } from "react";
import axiosConfig from "../../utils/axiosConfig";
import SideMenu from "./SideMenu";
import { Routes, Route } from "react-router-dom";
import UsersManagement from "./UsersManagement";
import styled from "@emotion/styled";
import Logs from "./Logs";

const ContainerAdmin = styled("div")({
  display: "flex",
});
const ContainerSideBar = styled("div")({
  width: "180px",
  minWidth: "180px",
  padding: "10px",
  minHeight:"100vh",
  backgroundColor: "#fafafb"
});
const ContainerContent = styled("div")({
  flexGrow:1,
  padding: "10px",
  backgroundColor: "#fff",
  height: "100vh",
  overflowY: 'auto',
});

const Admin = () => {
  useEffect(() => {
    const callAdminOnlyView = async () => {
      try {
        await axiosConfig.get(process.env.REACT_APP_API_CHECK_ADMIN);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    callAdminOnlyView();
  }, []);

  return (
    <ContainerAdmin>
      <ContainerSideBar>
        <SideMenu />
      </ContainerSideBar>
      <ContainerContent>
        <Routes>
          <Route path="user-management" element={<UsersManagement />} />
          <Route path="logs" element={<Logs />} />
        </Routes>
      </ContainerContent>
    </ContainerAdmin>
  );
};

export default Admin;
