import { Box,Slide } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../Common/CustomButton";

const HeroSection = () => {
  const scrollToServices = () => {
    const servicesElement = document.getElementById('services');
    if (servicesElement) {
      servicesElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setShowContent(true);
    }, 500); 

    return () => clearTimeout(delayTimeout);
  }, []);


  return (
    <div className="hero-container" >
        <Slide in={showContent} direction="right" timeout={1000} mountOnEnter unmountOnExit>
          <div className="hero-content">
            <Box className="headline">From Vision to Success, Unleashing Exponential Marketing and Software Excellence</Box>
            <CustomButton title="Let's Build Together" onClick={scrollToServices}/>
          </div>
        </Slide>
    </div>
  );
};

export default HeroSection;
