import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import { Provider } from 'react-redux';
import { store } from './utils/store';
import App from './App';
import './index.css'; // Add this line to import index.css


const root = createRoot(document.getElementById('root')); // Create a root

root.render( // Use the root.render method
  <Provider store={store}>
      <App />
  </Provider>
);
