import React from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import CodeIcon from '@mui/icons-material/Code';
import StoreIcon from '@mui/icons-material/Store';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import BuildIcon from '@mui/icons-material/Build';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

const cardStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between', // Distribute space evenly
  height: '100%', // Make card fill the parent container
  padding: '2rem', // Add some padding inside the card
  boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', // subtle shadow for depth
  borderRadius: '8px', // Optional: rounded corners for a softer look
};

const iconStyles = {
  '& svg': { // Target the svg element directly for icon size
    fontSize: '4rem', // Larger icon size
    marginBottom: '1rem', // Space below icon
    color: '#fd2049', // Use primary color from theme
  }
};

const titleStyles = {
  fontWeight: 'bold',
  fontSize: '1.5rem', // Larger font size for title
  marginBottom: '0.5rem', // Space below title
};

const contentStyles = {
  fontSize: '1rem', // Adjust content font size if necessary
};

const ServiceCard = ({ icon, title, description, link }) => (
<Card sx={cardStyles}>
    <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={iconStyles}>{icon}</Box>
      <Typography variant="h5" component="h2" gutterBottom sx={titleStyles}>
        {title}
      </Typography>
      <Typography variant="body1" sx={contentStyles}>
        {description}
      </Typography>
    </CardContent>
  </Card>
);

const ServicesSection = () => {

  return (
    <section className="services-section">
      <Typography sx={{ mb: 5 }} variant="h2" align="center" gutterBottom >
        Our Services
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard
            icon={<CodeIcon />}
            title="Custom Software & Web Development"
            description="Delivering comprehensive development services, from custom software to dynamic web applications, tailored to your business needs."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard
            icon={<DesignServicesIcon />}
            title="UX/UI Design"
            description="Crafting engaging and intuitive user experiences with a focus on user-centered design principles for websites and applications."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard
            icon={<BuildIcon />}
            title="Software Maintenance and Support"
            description="Ensuring the continued performance and reliability of your software solutions."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard
            icon={<AutoGraphIcon />}
            title="Digital Marketing"
            description="Driving your brand's online presence through strategic digital marketing initiatives."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard
            icon={<SmartToyIcon />}
            title="Automation and Integration"
            description="Streamlining operations with automated processes and seamless software integrations."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard
            icon={<StoreIcon />}
            title="E-Commerce Solutions"
            description="Creating online stores that optimize user shopping experiences and boost sales."
          />
        </Grid>

      </Grid>
    </section>
  );
};

export default ServicesSection;
