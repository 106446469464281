import React from "react";

const BaseModal = ({
  isOpen,
  onClose,
  onSave,
  title,
  subtitle,
  children,
  showSaveButton,
  formFields,
  saveButtonText = "Save", // default value is "Save"
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <header className="modal-header">
          <div>
            <div className="modal-title">{title}</div>
            <div className="modal-subtitle">{subtitle}</div>
          </div>
          <button className="modal-close-btn" onClick={onClose}>
            X
          </button>
        </header>
        <main className="modal-body">
          {formFields && (
            <form>
              {formFields.map((field, index) => (
                <div key={index} className="modal-body-form-field">
                  <label className="modal-body-form-label">{field.label}</label>
                  {field.type === "select" ? (
                    <select
                      className="modal-body-form-select"
                      value={field.value}
                      onChange={(e) => field.onChange(e)} // Pass the entire event
                    >
                      {field.options.map((option, optionIndex) => (
                        <option key={optionIndex} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      className="modal-body-form-input"
                      type={field.type || "text"}
                      value={field.value}
                      placeholder={field.placeholder || ""}
                      onChange={(e) => field.onChange(e)} // Pass the entire event
                    />
                  )}
                </div>
              ))}
            </form>
          )}
          {children}
        </main>
        <footer className="modal-footer">
          <button className="close" onClick={onClose}>
            Close
          </button>
          {showSaveButton && (
            <button className="save" onClick={onSave}>
              {saveButtonText}
            </button>
          )}
        </footer>
      </div>
    </div>
  );
};

export default BaseModal;
