import React, { useState, useEffect } from "react";
import Typography from "../Common/Typography";
import axiosConfig from "../../utils/axiosConfig";
import BaseModal from "../Common/Modal/Base";

const styles = {
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px", // Adjust the height as needed
    height: "100vh",
  },
  usersDashboard: {
    padding: "20px",
  },
  dashboardHeader: {
    marginBottom: "20px",
    fontFamily: "Arial, sans-serif",
    color: "#333",
  },
  dashboardActions: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "20px",
  },
  dateInput: {
    padding: "10px",
    border: "1px solid #e0e0e0",
    borderRadius: "5px",
    outline: "none",
    color: "#5a5c67",
    fontFamily: "Arial",
    fontSize: "12px",
  },
  searchButton: {
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};
function Logs() {
  const today = new Date().toISOString().split("T")[0];
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
    .toISOString()
    .split("T")[0];

  const [logs, setLogs] = useState([]);
  const [logLevel, setLogLevel] = useState("info"); 
  const [dateRange, setDateRange] = useState({ start: yesterday, end: today }); 
  
  useEffect(() => {
    fetchLogs();
  }, [logLevel]);

  const fetchLogs = async () => {
    const response = await axiosConfig.get(process.env.REACT_APP_API_GET_LOGS, {
      params: {
        level: logLevel,
        startDate: dateRange.start,
        endDate: dateRange.end,
      },
    });
    setLogs(response.data);
  };

  const handleLogLevelChange = (level) => {
    setLogLevel(level);
  };

  const handleDateChange = (start, end) => {
    setDateRange({
      start: start || dateRange.start,
      end: end || dateRange.end,
    });
  };

  const totalLogs = logs.length;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedExtraData, setSelectedExtraData] = useState({});

  const handleExtraDataClick = (extraData) => {
    setSelectedExtraData(extraData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div style={styles.usersDashboard}>
      <header style={styles.dashboardHeader}>
        <Typography variant="h3">Logs Overview</Typography>
        <Typography variant="p">
          Monitor user interactions, system warnings, and errors in real-time.
        </Typography>
      </header>
      <div style={styles.dashboardActions}>
        <input
          type="date"
          value={dateRange.start}
          style={styles.dateInput} // Apply the style here
          onChange={(e) => handleDateChange(e.target.value, dateRange.end)}
        />
        <input
          type="date"
          value={dateRange.end}
          style={styles.dateInput} // Apply the style here
          onChange={(e) => handleDateChange(dateRange.start, e.target.value)}
        />
        <button onClick={fetchLogs} style={styles.searchButton}>
          <i className="fa fa-search"></i>
        </button>
      </div>
      <div className="content-container">
        <div className="table-header">
          <h2>
            Logs <span className="order-count">{totalLogs}</span>
          </h2>
          <div className="table-filters">
            <button
              className={`filter-btn ${logLevel === "info" ? "active" : ""}`}
              onClick={() => handleLogLevelChange("info")}
            >
              Info
            </button>
            <button
              className={`filter-btn ${logLevel === "warning" ? "active" : ""}`}
              onClick={() => handleLogLevelChange("warning")}
            >
              Warning
            </button>
            <button
              className={`filter-btn ${logLevel === "error" ? "active" : ""}`}
              onClick={() => handleLogLevelChange("error")}
            >
              Error
            </button>
          </div>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Timestamp</th>
                <th>User</th>
                <th>Message</th>
                <th>Correlation ID</th>
                <th>Extra data</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{log.timestamp}</td>
                  <td>{log.user}</td>
                  <td>{log.message}</td>
                  <td>{log.correlation_id || "N/A"}</td>
                  <td>
                    {log.extra_data &&
                    Object.keys(log.extra_data).length > 0 ? (
                      <i
                        className="fa fa-eye"
                        onClick={() => handleExtraDataClick(log.extra_data)}
                        style={{ cursor: "pointer" }}
                      ></i>
                    ) : (
                      "N/A"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <BaseModal
          isOpen={isModalOpen}
          onClose={closeModal}
          title="Extra Data Details"
          subtitle=""
          children={<pre>{JSON.stringify(selectedExtraData, null, 2)}</pre>}
        />
      </div>
    </div>
  );
}

export default Logs;
