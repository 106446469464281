import React from "react";
import styled from "@emotion/styled";

const FullWidthContainer = styled.div`
  margin-top: ${({ isFirst }) => (isFirst ? "56px" : "0")};
  background-color: ${({ bgColor }) => bgColor};
  ${({ backgroundImage }) => backgroundImage ? `
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url(${backgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  ` : `
    padding: 50px 0px;
  `}
`;

const CenteredContentContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
`;

const Container = ({ children, bgColor, isFirst, backgroundImage, id }) => (
  <FullWidthContainer id={id} bgColor={bgColor} isFirst={isFirst} backgroundImage={backgroundImage} >
    <CenteredContentContainer>{children}</CenteredContentContainer>
  </FullWidthContainer>
);

export default Container;
