import React from 'react';
import { Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';

const CustomButton = ({ title, onClick, size, path, href, bgColor, textColor, type, margin = '0px' }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const getSize = (size) => {
    switch (size) {
      case 'small':
        return { fontSize: '1rem', padding: '5px 10px' };
      case 'large':
        return { fontSize: '1.5rem', padding: '15px 30px' };
      default:
        return { fontSize: '1.25rem', padding: '10px 20px' };
    }
  };

  const handleClick = () => {
    if (path) {
      navigate(path);
    }
    if (href) {
      window.location.href = href;
    }
    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  };

  const { fontSize, padding } = getSize(size);

  const buttonStyle = {
    backgroundColor: bgColor ? bgColor : colors.primary,
    color: textColor ? textColor : '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize,
    width: 'fit-content',
    padding,
    margin,
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: bgColor ? bgColor : colors.primary,
    },
  };

  return (
    <Button type={type} onClick={handleClick} sx={buttonStyle}>
      {title}
    </Button>
  );
};

export default CustomButton;
