import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        color: '#3f3f3f',
        padding: '0 0',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {/* All Rights Reserved Column */}
          <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
            <Typography variant="body2">
              © 2023 Vious Vision. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
