import React from 'react';
import { Box, Container, Grid, Typography, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../Common/CustomButton';

const ContactUsSection = () => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSendMessage = () => {
    const recipientEmail = 'contact@viousvision.com';
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const subject = document.getElementById('subject').value;
    const message = document.getElementById('message').value;

    const body = `Name: ${name}\nEmail: ${email}\nSubject: ${subject}\n\n${message}`;
    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the user's default email client
    window.location.href = mailtoLink;
  };

  return (
    <Box>
      <Container sx={{ marginTop: '80px' }} maxWidth="lg">
        <Grid container spacing={matchesSM ? 2 : 5}>
          <Grid item xs={12} md={6}>
            <Box style={{
              backgroundImage: `url(get_in_touch.jpeg)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%',
              minHeight: '500px',
              position: 'relative',
            }}>
              <Box style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: '#fd2049',
                opacity: 0.5,
              }} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h2" align="center" gutterBottom>
              Get in Touch
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
              If you would like more information about our solutions and services or if you simply want to get to know us better, please do not hesitate to contact us:
            </Box>
            <TextField id="name" fullWidth label="Your Name" variant="outlined" margin="normal" />
            <TextField id="email" fullWidth label="Email" variant="outlined" margin="normal" />
            <TextField id="subject" fullWidth label="Subject" variant="outlined" margin="normal" />
            <TextField id="message" fullWidth label="Your Message" multiline rows={4} variant="outlined" margin="normal" />
            <Box mt={2} display="flex" justifyContent="center">
              <CustomButton title="Send Message" bgColor="#fd2049" onClick={handleSendMessage} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactUsSection;
