import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axiosConfig from '../utils/axiosConfig';
import { logout } from '../reducers/userActions';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
 // Simple Spinner Component
 const Spinner = () => (
  <div className="spinner-container">
    <div className="loading-spinner"></div>
  </div>
);
useEffect(() => {
  const performLogout = async () => {
    try {
      const response = await axiosConfig.post(process.env.REACT_APP_API_LOGOUT);
      if (response.status === 200) {
        dispatch(logout()); // Dispatch the logout action only if the response is 200
      } else {
        // If the response is not 200, handle it here
      }
    } catch (error) {
      // Handle other errors here
    } finally {
      navigate('/'); // Redirect to home or login page regardless of the response
    }
  };

  performLogout();
}, [dispatch, navigate]);


  return <Spinner />;
};

export default Logout;
