import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axiosConfig from "../utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies] = useCookies(['is_logged_in']);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      alert('hello');
      const response = await axiosConfig.post(process.env.REACT_APP_API_LOGIN, {
        email: email,
        password: password,
      });
      console.log(response);
      dispatch({ type: "SET_USER_INFO", payload: response.data.user_info });
      if( response.data.user_info['role']==='admin'){
        navigate("/admin/user-management"); // Navigate to homepage on successful login
      }
      else{
        navigate("/"); // Navigate to homepage on successful login
      }
    } catch (error) {
      // Handle login error here
    }
  };

  useEffect(() => {
    if (cookies.is_logged_in) { //is_logged_in && response!== status.code 200
      navigate('/');
    }
  }, [cookies, navigate]);

  return (
    <div>
      <form onSubmit={handleSubmit} className="login-form">
      <h1>Login</h1>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
