import React, {useEffect, useState} from 'react'; // Import the react-ga4 module
import ReactGA from 'react-ga4';
import Home from './components/Home/Home';
import Login from './components/Login';
import Logout from './components/Logout';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import axiosConfig from './utils/axiosConfig';
import { useDispatch } from 'react-redux';
import { setUser } from './reducers/userActions';
import Register from './components/Register';
import { useCookies } from 'react-cookie';
import Admin from './components/Admin/Admin';
import EmailVerification from './components/EmailVerification';

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    // Initialize GA4 with your 'G-' Measurement ID
    ReactGA.initialize('G-7X4B6BEDL7'); // Replace with your actual measurement ID
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
}

// Component to track page views
function TrackPageViews() {
  usePageViews();
  return null; // This component doesn't need to render anything
}

function App() {
  
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [cookies] = useCookies(['is_logged_in']);

  // Simple Spinner Component
  const Spinner = () => (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );

  useEffect(() => {

    const checkSession = async () => {
      if (cookies.is_logged_in) {
        try {
          setIsLoading(true);
          const response = await axiosConfig.post(process.env.REACT_APP_API_CHECK_SESSION);
          if(response.data.status!==401){
            dispatch(setUser(response.data.user_info));
          }
        } catch (error) {
          console.error('Session check failed:', error);
        } finally {
          setIsLoading(false);
        }
      }else {
        setIsLoading(false);
      }

    };

    checkSession();
  }, [cookies, dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Router>
      <TrackPageViews/>
      <div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/dashboard" element={<ProtectedRoute allowedRoles={['user']}><Dashboard /></ProtectedRoute>} />
          <Route path="/admin/*" element={<ProtectedRoute allowedRoles={['admin']}><Admin /></ProtectedRoute>} />
          <Route path="/" element={<Home />} />
          <Route path="/confirm-email/:uid/:token" element={<EmailVerification />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
