import React, { useState, useEffect, useRef, useCallback } from "react";
import axiosConfig from "../../utils/axiosConfig";
import BaseModal from "../Common/Modal/Base";
import Typography from "../Common/Typography";

function Spinner() {
  const styles = {
    spinner: {
      width: "50px",
      height: "50px",
      border: "5px solid #f3f3f3", // Light grey border
      borderTop: "5px solid #3498db", // Blue color
      borderRadius: "50%",
      animation: "spin 1s linear infinite",
    },
  };

  return <div style={styles.spinner}></div>;
}
function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}
const styles = {
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px", // Adjust the height as needed
    height: "100vh",
  },
  usersDashboard: {
    padding: "20px",
  },
  dashboardHeader: {
    marginBottom: "20px",
    fontFamily: "Arial, sans-serif",
    color: "#333",
  },
  dashboardActions: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "20px",
  },
  searchInput: {
    padding: "10px",
    border: "1px solid #e0e0e0",
    borderRadius: "5px",
    outline: "none",
  },
  addButton: {
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  tableStyle: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  },
  tableHeaderFooter: {
    textAlign: "left",
    padding: "15px",
    backgroundColor: "#f9f9f9",
    color: "#333",
    borderBottom: "1px solid #e0e0e0",
  },
  tableData: {
    textAlign: "left",
    padding: "15px",
    borderBottom: "1px solid #e0e0e0",
  },
  roleBadge: {
    padding: "3px 8px",
    borderRadius: "12px",
    color: "#333",
    fontWeight: "500",
    backgroundColor: "rgba(243, 243, 243, 0.7)",
    border: "1px solid #e0e0e0",
    fontSize: "0.8em",
  },
};

function UsersManagement() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalAddOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    password: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);

  function MoreOptions({ user }) {
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const menuRef = useRef();

    useOutsideClick(menuRef, () => {
      if (isOpen) setIsOpen(false);
    });

    const handleToggle = (event) => {
      setIsOpen(!isOpen);
      setPosition({
        top: event.clientY,
        left: event.clientX,
      });
    };

    const handleEdit = () => {
      handleEditClick(user);
      setIsOpen(false);
    };

    const handleDelete = () => {
      handleDeleteClick(user.id);
      setIsOpen(false);
    };

    return (
      <div>
        <button className="option" onClick={handleToggle}>...</button>
        {isOpen && (
          <div
            ref={menuRef}
            className="popupMenu"
            style={{ top: `${position.top}px`, left: `${position.left}px` }}
          >
            <button onClick={handleEdit}>Edit</button>
            <button onClick={handleDelete}>Delete</button>
          </div>
        )}
      </div>
    );
  }

  const handleEditClick = (user) => {
    setSelectedUser({
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      role: user.role,
      password: "",
    });
    setEditModalOpen(true);
  };

  const handleUpdateUser = async () => {
    setIsLoading(true);
    try {
      setEditModalOpen(false);
      await axiosConfig.put(`${process.env.REACT_APP_API_UPDATE_USER}${selectedUser.id}/`, {
        first_name: selectedUser.firstName,
        last_name: selectedUser.lastName,
        email: selectedUser.email,
        role: selectedUser.role,
      });
      // Refresh the user list to show updated details
      await fetchUsers(currentPage);
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (userId) => {
    setUserToDelete(userId);
    setDeleteModalOpen(true);
  };
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await axiosConfig.delete(`${process.env.REACT_APP_API_DELETE_USER}${userToDelete}`);

      // Refresh the user list to remove the deleted user
      await fetchUsers(currentPage);
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setDeleteModalOpen(false);
      setIsLoading(false);
    }
  };

  const handleOpen = () => setModalAddOpen(true);
  const handleClose = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setModalAddOpen(false);
  };
  const handleAdd = async () => {
    setIsLoading(true);
    try {
      const newUser = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        // Assuming 'role' is a field in your user model; adjust if it's different
        role: role,
      };

      await axiosConfig.post(process.env.REACT_APP_API_ADD_USER, newUser);

      // Clear the form fields
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setRole("");

      // Close the modal and refresh the user list
      setModalAddOpen(false);
      await fetchUsers(currentPage);
    } catch (error) {
      console.error("Error adding new user:", error);
      // Handle the error appropriately - e.g., show an error message
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const fetchUsers = useCallback( async (page, search = "") => {
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_GET_ALL_USERS}?page=${page}&page_size=${pageSize}&search=${search}`;
      const response = await axiosConfig.get(url);
      setUsers(response.data.results);
      setTotalUsers(response.data.count);
      setNextPageUrl(response.data.next);
      setPrevPageUrl(response.data.previous);
      setCurrentPage(page); // Update the current page here
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  }, [pageSize]); 

  const handleSearch = () => {
    setCurrentPage(1); // Reset to the first page on search
    fetchUsers(1, searchTerm);
  };

  const handleNextPage = () => {
    if (nextPageUrl) fetchUsers(currentPage + 1, searchTerm);
  };

  const handlePrevPage = () => {
    if (prevPageUrl) fetchUsers(currentPage - 1, searchTerm);
  };

  // Calculate total pages
  const totalPages = Math.ceil(totalUsers / pageSize);

  useEffect(() => {
    fetchUsers(1, searchTerm); 
  }, [fetchUsers, pageSize]);

  return (
    <div style={styles.usersDashboard}>
      <header style={styles.dashboardHeader}>
        <Typography variant="h3">Users Management</Typography>
        <Typography variant="p">
          Your central hub for managing user profiles, preferences, and activity
          in real-time.
        </Typography>
      </header>
      <div style={styles.dashboardActions}>
        <input
          type="search"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          style={styles.searchInput}
        />
        <button onClick={handleSearch} style={styles.addButton}>
          <i className="fa fa-search"></i>
        </button>
        <button onClick={handleOpen} style={styles.addButton}>
          Add user +
        </button>
      </div>
      <section>
        {isLoading ? (
          <div style={styles.spinnerContainer}>
            <Spinner />
          </div>
        ) : (
          <div>
            <div className="content-container">
              <div className="table-header">
                <h2>
                  Users <span className="order-count">{totalUsers}</span>
                </h2>
                <div className="table-filters">
                  {/*<button class="filter-btn active">Info</button>*/}
                  {/*<button class="filter-btn active">Warning,</button>*/}
                </div>
              </div>
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Verified</th>
                      <th>Role</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={user.id}>
                        <td>{index}</td>
                        <td>{user.first_name}</td>
                        <td>{user.last_name}</td>
                        <td>{user.email}</td>
                        <td>
                          <span>{user.is_email_verified ? "Yes" : "No"}</span>
                        </td>
                        <td>
                          <span style={styles.roleBadge}>{user.role}</span>
                        </td>
                        <td style={styles.tableData}>
                          <MoreOptions user={user} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination">
                  <button
                    className="page-link filter-btn"
                    onClick={handlePrevPage}
                    style={styles.pageLink}
                  >
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                  </button>
                  <span className="pagination-info">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    className="page-link filter-btn"
                    onClick={handleNextPage}
                    style={styles.pageLink}
                  >
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <div>
        <BaseModal
          isOpen={modalOpen}
          onClose={handleClose}
          onSave={handleAdd}
          title="Add a new user"
          subtitle="Create a new user profile by providing the required information below."
          showSaveButton={true}
          formFields={[
            {
              label: "First name",
              type: "text",
              value: firstName,
              placeholder: "Enter user's first name",
              onChange: (e) => setFirstName(e.target.value),
            },
            {
              label: "Last name",
              type: "text",
              value: lastName,
              placeholder: "Enter user's last name",
              onChange: (e) => setLastName(e.target.value),
            },
            {
              label: "Email",
              type: "email",
              value: email,
              placeholder: "Enter user's email",
              onChange: (e) => setEmail(e.target.value),
            },
            {
              label: "Role",
              type: "select",
              value: role,
              onChange: (e) => setRole(e.target.value),
              options: [
                { value: "user", label: "User" },
                { value: "admin", label: "Admin" },
              ],
            },
            {
              label: "Password",
              type: "password",
              value: password,
              placeholder: "Enter a secure password",
              onChange: (e) => setPassword(e.target.value),
            },
          ]}
        />

        <BaseModal
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onSave={() => handleDelete(userToDelete)} // Implement this function to handle deletion
          title="Delete user"
          subtitle="Confirm deletion of this user and all their associated data."
          showSaveButton={true}
          saveButtonText="Delete" // Here's the dynamic text
        >
          {`Are you sure you want to delete the user with id: ${userToDelete}?`}
        </BaseModal>
        <BaseModal
          isOpen={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          onSave={handleUpdateUser}
          title="Edit user profile"
          subtitle="Update the user's information below."
          showSaveButton={true}
          saveButtonText="Update"
          formFields={[
            {
              label: "First Name",
              type: "text",
              value: selectedUser.firstName,
              onChange: (e) =>
                setSelectedUser({ ...selectedUser, firstName: e.target.value }),
            },
            {
              label: "Last Name",
              type: "text",
              value: selectedUser.lastName,
              onChange: (e) =>
                setSelectedUser({ ...selectedUser, lastName: e.target.value }),
            },
            {
              label: "Email",
              type: "email",
              value: selectedUser.email,
              onChange: (e) =>
                setSelectedUser({ ...selectedUser, email: e.target.value }),
            },
            {
              label: "Role",
              type: "select",
              value: selectedUser.role,
              onChange: (e) =>
                setSelectedUser({ ...selectedUser, role: e.target.value }),
              options: [
                { value: "user", label: "User" },
                { value: "admin", label: "Admin" },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
}

export default UsersManagement;
