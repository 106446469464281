import React from "react";
import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";
import Typography from "../Common/Typography";

const SideMenuContainer = styled("nav")({
  padding: 0,
  transition: "width 0.3s",
});

const SidebarHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const Logo = styled("img")({
  height: "auto",
  width: "50%",
  display: "block",
  margin: "25px auto",
});

const MenuList = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
});
const MenuItem = styled("li")({
  display: "flex",
  alignItems: "center",
  padding: "10px 20px",
  marginBottom: "10px",
  borderRadius: "35px", // Default border-radius
  transition: "background-color 0.3s ease", // Include border-radius in transition
  "&:hover": {
    backgroundColor: "rgb(211, 211, 211, 0.9)",
    borderRadius: "35px",
  },
});

const MenuLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  color: "#626262",
  width: "100%",
});

const MenuIcon = styled("i")({
  width: "30px",
  textAlign: "center",
  marginRight: "10px",
});

const activeStyle = {
  backgroundColor: "rgb(211, 211, 211, 0.9)",
  borderRadius: "35px",
};

function SideMenu() {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <SideMenuContainer>
      <SidebarHeader>
        <Logo
          src="https://y6b8k9e7.stackpathcdn.com/wp-content/uploads/2022/07/IIoT_World_Connecting_the_SMARTS_color-version.svg"
          alt="Logo"
        />
      </SidebarHeader>
      <MenuList>
        <MenuItem>
          <MenuLink to="/" title="Home">
            <MenuIcon className="fa fa-home" />
            <Typography variant="span">Home</Typography>
          </MenuLink>
        </MenuItem>
        <MenuItem style={isActive("/admin/user-management") ? activeStyle : {}}>
          <MenuLink to="/admin/user-management" title="Users">
            <MenuIcon className="fa fa-users" />
            <Typography variant="span">Users</Typography>
          </MenuLink>
        </MenuItem>
        <MenuItem style={isActive("/admin/logs") ? activeStyle : {}}>
          <MenuLink to="/admin/logs" title="Logs">
            <MenuIcon className="fa fa-history" />
            <Typography variant="span">Logs</Typography>
          </MenuLink>
        </MenuItem>
        <MenuItem style={isActive("/admin/logout") ? activeStyle : {}}>
          <MenuLink to="/logout" title="logout">
            <MenuIcon className="fa fa-sign-out" />
            <Typography variant="span">Logout</Typography>
          </MenuLink>
        </MenuItem>
      </MenuList>
    </SideMenuContainer>
  );
}

export default SideMenu;
