import React from 'react';
import PropTypes from 'prop-types';

const styles = {
    h1: {
        fontSize: '2.5em',
        fontWeight: 'bold',
        margin: '8px 0px',
    },
    h2: {
        fontSize: '2em', 
        fontWeight: 'bold',
        margin: '8px 0px',
    },
    h3: {
        fontSize: '1.75em',
        fontWeight: 'bold',
        margin: '8px 0px',
    },
    h4: {
        fontSize: '1.5em',
        fontWeight: 'bold',
        margin: '8px 0px',
    },
    h5: {
        fontSize: '1.25em',
        fontWeight: 'bold',
        margin: '8px 0px',
    },
    h6: {
        fontSize: '1.1em',
        fontWeight: 'bold',
        margin: '8px 0px',
    },
    p: {
        fontSize: '1em',
        fontWeight: 'normal',
        margin: '8px 0px',
    },
    span: {
        fontSize: '1em',
        fontWeight: 'normal',
        margin: '8px 0px',
        // add more styles as needed
    },
    // Add more variants as needed
};

const Typography = ({ variant, className, style, children }) => {
    const CustomTag = variant in styles ? variant : 'p';
    const combinedStyle = { ...styles[CustomTag], ...style };

    return (
        <CustomTag className={className} style={combinedStyle}>
            {children}
        </CustomTag>
    );
};

Typography.propTypes = {
    variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5','h6', 'p', 'span']),
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node.isRequired
};

Typography.defaultProps = {
    variant: 'p',
    className: '',
    style: {}
};

export default Typography;
