import React, { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useSelector } from 'react-redux';


const NavigationBar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const userInfo = useSelector(state => state.user.userInfo);

  const handleScroll = () => {
    setIsSticky(window.scrollY > 50 || window.scrollY === 0);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false); // Close menu on large screens
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);

    };
  }, []);

  const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledMenuIcon = styled(MenuIcon)`
  color: ${({ theme }) => theme.palette.secondary.main};
  transition: color 0.3s ease, transform 0.3s ease; /* Added transform transition */

  /* Rotate 45 degrees when the menu is open */
  transform: rotate(${({ isMenuOpen }) => (isMenuOpen ? "45deg" : "0deg")});
  
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.secondary.main};
  transition: color 0.3s ease, transform 0.3s ease; /* Added transform transition */

  /* Rotate 45 degrees when the menu is open */
  transform: rotate(${({ isMenuOpen }) => (isMenuOpen ? "45deg" : "0deg")});
  
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const smoothScrollTo = (element) => {
  const startPosition = window.pageYOffset;
  const targetPosition = element.getBoundingClientRect().top + startPosition;
  const startTime = performance.now();
  const duration = 1000; // Duration in milliseconds

  const easeInOutQuad = (time, start, change, duration) => {
    time /= duration / 2;
    if (time < 1) return (change / 2) * time * time + start;
    time--;
    return (-change / 2) * (time * (time - 2) - 1) + start;
  };

  const animation = (currentTime) => {
    const timeElapsed = currentTime - startTime;
    const run = easeInOutQuad(timeElapsed, startPosition, targetPosition - startPosition, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animation);
  };

  requestAnimationFrame(animation);
};

const handleInternalLinkClick = (event) => {
  const { target } = event;
  const href = target.getAttribute('href');
  if (href && href.startsWith('/#')) {
    event.preventDefault();
    const id = href.substring(2); // Remove the '#' to get the id
    const element = document.getElementById(id);
    if (element) {
      smoothScrollTo(element);
    }
  }
};

  return (
    <nav className={`navbar ${isSticky ? "sticky" : ""}`} style={{backgroundColor:colors.background}}>
      <div className="navbar-content">
        <div className="navbar-logo" style={{color:colors.secondary}}>V I O U S&emsp;V I S I O N</div>
        <div className={`menu-toggle menu-icon ${isMenuOpen ? "open rotate" : ""}`} onClick={toggleMenu}>
          {isMenuOpen ? <StyledCloseIcon /> : <StyledMenuIcon />}
        </div>
        <ul className={`navbar-options ${isMenuOpen ? "open" : ""}`} style={{backgroundColor:colors.background}} onClick={handleInternalLinkClick}>
          <li>
            <StyledLink to="#home" className="navbar-link">Home</StyledLink>
          </li>
          <li>
            <StyledLink to="#aboutus" className="navbar-link">About</StyledLink>
          </li>
          <li>
            <StyledLink to="#services" className="navbar-link">Services</StyledLink>
          </li>
          <li>
            <StyledLink to="#contact" className="navbar-link">Contact</StyledLink>
          </li>
          {userInfo ? (
          <>
            {userInfo.role === 'user' && <li><StyledLink to="/dashboard">Dashboard</StyledLink></li>}
            {userInfo.role === 'admin' && <li><StyledLink to="/admin/user-management">Admin</StyledLink></li>}
          </>
        ) : (
          <>

          </>
        )}
        </ul>
      </div>
    </nav>
  );
};

export default NavigationBar;